<template>
  <div class="grid grid-flow-row-dense grid-cols-3 gap-2 p-0 xs:grid-cols-3 xs:gap-6 xs:p-2">
    <label v-for="choice in avatar_options" :key="`avatar_choice_${choice}`"
      class="relative h-auto rounded-full ring ring-transparent focus-within:ring-white"
      :class="{ 'shadow-2xl ring-white': avatarSelected == choice }">
      <div class="overflow-hidden rounded-full aspect-w-1 aspect-h-1">
        <img :src="require(`assets/avatars/avatar_${choice}.png`)" class="object-cover w-full h-full" />
      </div>
      <div class="absolute w-1/4 h-1/4 bottom-3/4 left-3/4">
        <input type="radio" class="w-3 h-3 focus:outline-none text-brand-primary active:bg-brand-primary"
          :value="choice" v-model="avatarSelected" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "AvatarSelector",
  props: {
    avatar: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    avatarSelected: null,
    avatar_options: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
  }),
  watch: {
    avatarSelected: function (newVal) {
      this.$emit("avatar-changed", newVal);
    },
  },
  mounted() {
    this.avatarSelected = this.avatar;
  },
};
</script>
