<template>
  <form @submit.prevent="onSubmit">
    <div class="p-4 overflow-y-scroll text-sm leading-normal prose break-words bg-white rounded-md max-h-80 sm:prose-sm max-w-none">
      <div v-html="content"></div>
    </div>

    <t-button type="button" variant="white" @click="onPrevStepClick" class="my-4">{{ $t("auth.form.previous_step") }}</t-button>

    <div class="flex gap-4">
      <t-button type="submit">{{ $t("auth.form.save_and_register") }}</t-button>
    </div>
  </form>
</template>

<script>
import api from "utils/api";

export default {
  name: "RegisterStepFour",

  mounted() {
    api
      .get("privacy-app", { params: { language: this.$i18n.locale }})
      .then((response) => {
        this.content = response.data;
      })
      .catch(() => {
        this.content =
          '<p><i>Failed to load contents of Privacy &amp; Cookie policy.</i></p><p>Try again later or visit <a href="https://budd.be/" target="_blank" rel="noopener">https://budd.be/</a>.</p>';
      })
      .then(() => {
        this.loading = false;
      });
  },

  data() {
    return {
      loading: true,
      content: null,
    };
  },

  methods: {
    onSubmit() {
      this.$emit("onSubmit", "RegisterUser");
    },

    onPrevStepClick() {
      this.$emit("onPrevStepClick", "RegisterStepThree");
    },
  },
};
</script>
