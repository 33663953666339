<template>
  <form
    @submit.prevent="onSubmit"
    class="flex flex-col space-y-2"
    aria-autocomplete="off"
    autocomplete="off"
  >
    <avatar-selector @avatar-changed="onAvatarChange"></avatar-selector>

    <t-button type="button" variant="white" @click="onPrevStepClick">{{
      $t("auth.form.previous_step")
    }}</t-button>

    <t-button type="submit">{{ $t("auth.form.next_step") }}</t-button>
  </form>
</template>

<script>
import AvatarSelector from "../../AvatarSelector.vue";

export default {
  components: { AvatarSelector },
  name: "RegisterStepTwo",

  data() {
    return {
      avatar: null,
    };
  },

  methods: {
    onAvatarChange(avatar) {
      this.avatar = avatar;
    },

    onSubmit() {
      this.$emit("onSubmit", "RegisterStepThree", { avatar: this.avatar });
    },

    onPrevStepClick() {
      this.$emit("onPrevStepClick", "RegisterStepOne");
    },
  },
};
</script>
