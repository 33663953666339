<template>
  <form
    @submit.prevent="onSubmit"
    @keydown="form.onKeydown($event)"
    name="login"
    class="flex flex-col"
    autocomplete="off"
  >
    <div class="flex flex-col space-y-3">
      <t-input-group :label="$t('auth.form.name')">
        <t-input
          v-model="form.name"
          required
          type="text"
          name="name"
          :variant="{ danger: form.errors.has('name') }"
        />
      </t-input-group>

      <t-input-group :label="$t('auth.form.email')">
        <t-input
          v-model="form.email"
          required
          type="email"
          name="email"
          :variant="{ danger: form.errors.has('email') }"
        />

        <p class="text-sm text-red-400" v-if="form.errors.has('email')">
          {{ form.errors.get("email") }}
        </p>
      </t-input-group>

      <t-input-group :label="$t('auth.form.password')">
        <t-input
          v-model="form.password"
          required
          type="password"
          name="password"
          :variant="{ danger: form.errors.has('password') }"
        />

        <p class="text-sm text-red-400" v-if="form.errors.has('password')">
          {{ form.errors.get("password") }}
        </p>
      </t-input-group>

      <t-input-group :label="$t('auth.form.confirm_password')">
        <t-input
          v-model="form.password_confirmation"
          required
          type="password"
          name="password_confirmation"
          :variant="{ danger: form.errors.has('password_confirmation') }"
        />

        <p
          class="text-sm text-red-400"
          v-if="form.errors.has('password_confirmation')"
        >
          {{ form.errors.get("password_confirmation") }}
        </p>
      </t-input-group>

      <label class="flex rounded-lg bg-white py-3 px-2 text-xs font-bold">
        <t-checkbox class="mr-2" v-model="form.enable_analytics"></t-checkbox>
        <span>
          {{ $t("shared.enable_analytics") }}
        </span>
      </label>
    </div>

    <t-button type="submit" class="mt-6">
      {{ $t("auth.form.next_step") }}
    </t-button>
  </form>
</template>

<script>
import Form from "vform";

export default {
  name: "RegisterStepOne",

  props: {
    errors: {
      type: Array,
    },
  },

  data() {
    return {
      form: new Form({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        enable_analytics: false,
      }),
    };
  },

  methods: {
    async onSubmit() {
      this.form.errors.clear();

      try {
        const response = await this.form.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/validation`,
          {
            params: { type: "email" },
          }
        );

        if (response.data.success) {
          this.$emit("onSubmit", "RegisterStepTwo", {
            form: this.form.data(),
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
