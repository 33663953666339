<template>
  <div
    class="mx-auto flex h-full w-full max-w-sm flex-col justify-center self-center px-4"
  >
    <transition name="fade">
      <div
        class="mb-4 rounded-md bg-red-400 p-4 text-white"
        v-if="Object.values(errors || {}).length"
      >
        <ul>
          <li v-for="error in Object.values(errors)" :key="error">
            {{ error[0] }}
          </li>
        </ul>
      </div>
    </transition>

    <div class="flex items-baseline justify-between">
      <h1 class="mb-8 text-center text-2xl font-black">
        {{ $t("auth.title--register") }}
      </h1>
      <p class="text-sm">
        {{ $t("form.step-num-of-total", { num: step, total: 4 }) }}
      </p>
    </div>

    <keep-alive>
      <component
        :is="activeComponent"
        :errors="errors"
        @onSubmit="onNextStep"
        @onPrevStepClick="onPrevStep"
      ></component>
    </keep-alive>

    <div class="mt-8 space-y-4 text-center font-medium" v-if="step == 1">
      <p>
        {{ $t("auth.callout--login") }}
        <router-link variant="link" is="t-button" :to="{ name: 'login' }">{{
          $t("auth.login")
        }}</router-link>
      </p>

      <p class="maw-w-xs text-sm">
        {{ $t("auth.callout--policy") }}<br /><router-link
          variant="link"
          is="t-button"
          :to="{ name: 'policy' }"
          >{{ $t("pages.policy") }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import RegisterStepOne from "../../components/Auth/Register/StepOne.vue";
import RegisterStepTwo from "../../components/Auth/Register/StepTwo.vue";
import RegisterStepThree from "../../components/Auth/Register/StepThree.vue";
import RegisterStepFour from "../../components/Auth/Register/StepFour.vue";
import UserRepository from "../../repositories/UserRepository";
import { AUTH_REQUEST } from "../../store/actions/auth";
import { USER_REQUEST } from "../../store/actions/user";

export default {
  name: "Register",

  components: {
    RegisterStepOne,
    RegisterStepTwo,
    RegisterStepThree,
    RegisterStepFour,
  },

  data() {
    return {
      activeComponent: "RegisterStepOne",
      step: 1,
      data: null,
      errors: null,
    };
  },

  methods: {
    async onNextStep(activeComponent, data) {
      this.data = { ...this.data, ...data };

      if (activeComponent === "RegisterUser") {
        this.errors = null;

        try {
          await UserRepository.register(this.data.form);
          const device_name = this.$browserDetect.meta.ua;
          await this.$store.dispatch(AUTH_REQUEST, {
            email: this.data.form.email,
            password: this.data.form.password,
            device_name,
          });
          if (this.data.avatar) {
            await UserRepository.updateAvatar(this.data.avatar);
          }

          if (this.data.buddy.name && this.data.buddy.phone) {
            await UserRepository.addBuddy(
              this.data.buddy.name,
              this.data.buddy.phone
            );
          }

          await this.$store.dispatch(USER_REQUEST);
          this.$router.push({ name: "tutorial-index" });

          return;
        } catch (e) {
          this.errors = e.response.data.errors;

          return;
        }
      }
      this.activeComponent = activeComponent;
      this.step++;
    },

    onPrevStep(activeComponent) {
      this.activeComponent = activeComponent;
      this.step--;
    },
  },
};
</script>
