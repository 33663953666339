<template>
  <form @submit.prevent="onSubmit">
    <h2 class="mb-2 text-xl font-bold">
      {{ $t("pages.register.add_safety_buddy") }}
    </h2>

    <t-input-group :label="$t('profile.name')" class="mb-2">
      <t-input v-model="form.name" type="text" name="name" :variant="{ danger: form.errors.has('name') }" />
      <p v-if="form.errors.has('name')" class="text-sm text-red-400">
        {{ form.errors.get("name") }}
      </p>
    </t-input-group>

    <t-input-group :label="$t('profile.phone')">
      <t-input v-model="form.phone" type="text" name="phone" :variant="{ danger: form.errors.has('phone') }" />
      <p v-if="form.errors.has('phone')" class="text-sm text-red-400">
        {{ form.errors.get("phone") }}
      </p>
    </t-input-group>

    <div class="mt-6 space-y-2">
      <t-button type="button" variant="white" @click="onPrevStepClick">{{ $t("auth.form.previous_step") }}</t-button>

      <transition name="fade">
        <t-button v-if="hasFilledInBuddy" type="submit">{{ $t("auth.form.next_step") }}</t-button>
        <t-button v-else type="submit">{{ $t("auth.form.skip_and_next") }}</t-button>
      </transition>
    </div>
  </form>
</template>

<script>
import Form from "vform";

export default {
  name: "RegisterStepThree",

  data() {
    return {
      form: new Form({
        name: "",
        phone: "",
      }),
    };
  },

  methods: {
    async onSubmit() {
      if (!this.form.name || !this.form.phone) {
        this.$emit("onSubmit", "RegisterStepFour");
        return;
      }

      try {
        const response = await this.form.post(`${process.env.VUE_APP_API_BASE_URL}/api/validation`, {
          params: {
            type: "buddy",
          },
        });

        if (response.data.success) {
          this.$emit("onSubmit", "RegisterStepFour", {
            buddy: {
              name: this.form.name,
              phone: this.form.phone,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    onPrevStepClick() {
      this.$emit("onPrevStepClick", "RegisterStepTwo");
    },
  },

  computed: {
    hasFilledInBuddy() {
      return this.form.name && this.form.phone;
    },
  },
};
</script>

<style></style>
